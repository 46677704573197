import React from 'react';
import { Link } from "gatsby"

import Layout from "../../components/layout"
import logoBlack from '../../images/logo-black.jpg';
import TeamImage from '../../images/about/team.jpg';
import Sandip from '../../images/about/sandip.jpg';
import Prakash from '../../images/about/prakash.jpg';
import {Helmet} from "react-helmet";
import { FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const AboutPage = () => (
  <Layout>
  
    
    <div className="about-background sm:h-48 sma:h-64"> 
    <h1 className="text-3xl xl:m-64 md:m-16 sm:m-24 tracking-widest text-white text-uppercase text-center font-bold ">
      DRIVING SUCCESS WITH SALESFORCE    </h1></div>
    <Helmet>
        <title>Know Us Better | Cymetrix Software</title>
        <meta name="description" content="Cymetrix Software is a niche global technology company with offices in USA, India, UK and Japan. Get to know more about our tech services and how we help businesses drive growth in action."/>
        <meta name="keywords" content="cymetrix,salesforce crm software implementation partners india,salesforce cpq partner india,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce marketing cloud partners india,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india, Cymetrix Software, cymetrixsoft"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/about/company/" />
      </Helmet>
      
    <section className="lg:flex lg:container mx-auto mt-16 my-10">
      <div className="w-full md:w-4/5 mx-auto">
        <img src={logoBlack} title="Cymetrix Software Logo | Top Salesforce Consulting Firm" alt="Cymetrix Software Logo Image" className="h-32 w-48 m-auto"/>
      </div>
      <div className="w-full md:w-4/5 lg:m-auto sma:px-2 sma:mt-10 mx-auto">
        <h1 className="text-3xl text-hover">About Cymetrix Software</h1>
        <div className="w-full bg-gray-900 h-px"></div>
        <span className="mt-4 block text-gray-800 font-medium text-xl text-justify">Cymetrix Software was founded in 2016 based on one principle “simplifying doing business with innovative IT solutions”.</span>
        <p className="leading-relaxed mt-2 text-lg text-gray-700 font-thin text-justify">Cymetrix is a Strategic Salesforce Partner with Technology Expertise across the Salesforce Ecosystem. We are a reliable salesforce consulting partner in India and USA who focuses on planning and in-progress support of complicated Salesforce applications. The pioneering work by our team on the Salesforce platform has won national recognition. We have a tendency to focus on building and maintaining absolutely the best skills possible. As a leading Salesforce partner in USA and India, we have a skilled team of certified Salesforce consultants. A minimum of two Salesforce.com certifications are applicable to 100% of our Salesforce consultants, and most have three or four. Cymetrix is the leading salesforce based company in USA. ProjectForce, our implementation tool, is a specific custom-made edition of Salesforce that allows us to drive clear and transparent projects. We have a passionate team of Salesforce consultants, architects, developers, and testers who are always ready to walk that extra mile and deliver the solutions best suited for the business. Cymetrix is led by industry veterans with extensive IT industry experience and delivering solutions to global clients. The best of the class’s global experience of our founders reflect in the delivery process rigor. Cymetrix an offshore software development company can handle complex Salesforce work for various industries. The company's salesforce CRM consulting services portfolio includes:</p>
        <div className="leading-loose mt-2  mx-5 text-gray-700 font-thin">
        <ul className="list-disc">
                                <li><Link to="/services/salesforce-consulting" className="underline">Salesforce Consulting</Link></li>
                                <li>Business Intelligence</li>
                                <li>CRM Consultants</li>
                                <li>Digital Marketing</li>
                                <li>360° Customer Dashboard</li>
                                <li><Link to="/services/spend-analysis" className="underline">Spend Analysis</Link></li>
                            </ul>
        </div>
        <h2 className="mt-10 block text-gray-700 text-lg font-black">Headquartered in India, Cymetrix has offices in Mumbai and San Francisco, USA</h2>
      </div>
    </section>

    <div className="product-background w-full flex flex-col items-center" title="Background image for company" alt=" Company Service Background  | Cymetrix Software">
    <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
    <div className="max-w-6xl mx-4 my-8">
                <h1 className="text-white text-2xl sma-xl font-thin">Our Values, Mission and Culture</h1>
                <p className="text-white text-xl md:text-xl sm:text-xl  sma-xl font-thin mt-6">We’re a start-up with passionate team which is always ready to walk that extra mile to deliver the solutions best suited for your business
</p>
<div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
    <div class=" mt-4 sm:w-1/2">
                        <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">CORE VALUES</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                            <div className="mt-2 text-base text-gray-300">
                              <ul className="list-disc text-base list-outside leading-relaxed px-4">
                                <li><strong>Ethics:</strong> We believe in doing business in ethical way</li>
                                <li><strong>Professionalism:</strong> We believe in deliberating professionalism in every aspect of business and client relationships</li>
                                <li><strong>Flexibility:</strong> We respect change and being flexible to accommodate it</li>
                                <li><strong>Putting skin in the game:</strong> We believe that business partnership cannot be successful till we are equal owner of outcome</li>
                              </ul>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-4 sm:w-1/2">
                    <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
            <div className="text-white text-center text-lg">CORE MISSION</div>
            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
            <div className="mt-2 text-base text-gray-300">
              <p className="text-center text-gray-400">Our core mission serves as a 'North Star' to keep all the team members focussed on the company objectives and defines the approach to achieve those objectives.</p>
              <ul class=" ml-2 list-disc list-inside">
                                <li><strong>Reliable Delivery</strong></li>
                                <li><strong>Flexibility</strong></li>
                                <li><strong>Customer Experience</strong></li>
                              
                              </ul>
            </div>
          </div>
        </div>
                    </div>
                    </div>
                    </div>
    </div>

    <section className="bg-bgcolor h-auto mb-10">
      <div className="flex justify-center px-2 py-3">
        <img src={TeamImage} alt="Cymetrix Software Team Image" title="Cymetrix Software Team" />
      </div>
      <div className="lg:container mx-auto">
        <div className="sma:p-2 mt-8">
          <h1 style={{color: '#2b56a9'}} className="text-xl font-bold">How we elevate CUSTOMER EXPERIENCE?</h1>
          <p className="mt-5 text-gray-700">We place client satisfaction and experience in front of all issues. It is not something that we do by going out of our way, but is an integral part of our work culture.</p>
          <ul className="list-disc list-inside mt-5">
            <li className="mt-2 text-gray-700">Compassionate team to consider the relevance of your problem. </li>
            <li className="mt-2 text-gray-700">Project possession to assist us, on behalf of our client, to think long term to minimize their risk and to ask enthusiastically for our thoughts.</li>
            <li className="mt-2 text-gray-700">Experienced, professional and well rounded team ensuring successful delivery</li>
          </ul>
        </div>

        <div className="sma:p-2 mt-8">
          <h1 style={{color: '#2b56a9'}} className="text-xl font-bold">How we achieve RELIABLE DELIVERY?</h1>
          <p className="mt-5 text-gray-700">We are aware of our responsibility in your growing business environment. Ensuring quality IT solutions to improve your business performance is our priority.</p>
          <ul className="list-disc list-inside mt-5">
            <li className="mt-2 text-gray-700">Almost entire team is Salesforce certified</li>
            <li className="mt-2 text-gray-700">Best of the class global experience of founders reflect in delivery process rigor</li>
            <li className="mt-2 text-gray-700">Innovative & out of the box thinking</li>
            <li className="mt-2 text-gray-700">Detailed business process analysis using domain expertise</li>
            <li className="mt-2 text-gray-700">Strategic workflow design and planning to optimize outcome</li>
          </ul>
        </div>

        <div className="sma:p-2 mt-8">
          <h1 style={{color: '#2b56a9'}} className="text-xl font-bold">What makes us FLEXIBLE?</h1>
          <p className="mt-5 text-gray-700">At Cymetrix, we love our detailed approach but we also appreciate the ever evolving nature of business environment. While we stay focussed on the big picture when it comes to deliverables, we also adapt to your shifting business priorities and emerging needs.</p>
          <ul className="list-disc list-inside mt-5">
            <li className="mt-2 text-gray-700">Agile methodology, quick turnaround time</li>
            <li className="mt-2 text-gray-700">Start-up Culture</li>
          </ul>
        </div>
      </div>
    </section>

    <section style={{backgroundColor: '#ddd'}} className="py-10">
      <div className="lg:flex lg-container mx-auto mt-16 max-w-6xl">
        <div className="w-full md:w-1/2 mx-auto flex flex-col justify-center">
          <img src={Sandip} title="Sandip Chintawar | Cymetrix Software CEO " alt="Cymetrix Software CEO Image - Sandip Chintawar" className="h-64 w-64 mx-auto my-5 rounded-full"/>
          <div className="flex flex-col justify-center">
            <h1 className="text-center text-lg text-gray-700">
              Sandip Chintawar
            </h1>
            <div class="flex justify-center space-x-4 mt-2">
              <a className="hover:text-hover" href="https://www.linkedin.com/in/schintawar/" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn />
              </a>
              <a className="hover:text-hover" href="https://twitter.com/schintawar" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
              {/* <Link
                className="hover:text-hover"
                to='https://www.linkedin.com/in/schintawar/'><FaLinkedinIn /></Link>
              <Link
                className="hover:text-hover"
                to='https://twitter.com/schintawar'><FaTwitter /></Link> */}
            </div>
          </div>
        </div>
        <div className="w-full md:w-4/5 lg:m-auto sma:px-2 sma:mt-10 mx-auto">
          <h1 className="text-3xl text-hover">Our Founder</h1>
          <div className="w-full bg-gray-900 h-px"></div>
          <p className="leading-relaxed mt-2 text-gray-700 font-thin text-justify">With a series of successful ventures under his belt, Sandip Chintawar founded Cymetrix Software in year 2016. Sandip spent fifteen years in Silicon Valley, California before moving to Mumbai and has experience in successfully launching multiple enterprise level IT products and solutions. He holds MS degree in Computer Science from Indian Institute of Technology (IIT), Roorkee.<br /><br />

            Prior to Cymetrix Software, Sandip was chairman and CEO of Arion Systems Pvt Ltd IT Consulting Company. Arion Systems was acquired by Persistent Systems Ltd. Before that, he founded the first cloud based web services software company in world ECtone Inc, which was eventually acquired by BEA Systems (BEAS).<br /><br />

            Sandip also founded and served as President and CTO of Aquas, Inc. (an e-Web-analytics solutions and software company, before Inteliant Corporation acquired in 1998).<br /><br />

            Sandip is active in investing and mentoring start-ups promoted by young entrepreneurs. He is also Charter Member of TIE (The Indus Entrepreneur) - a worldwide entrepreneurship development organization.<br />
          </p>
        </div>
      </div>

      <div className="lg:flex lg-container mx-auto mt-16 max-w-6xl">
        <div className="w-full md:w-1/2 mx-auto flex flex-col justify-center">
          <img src={Prakash} title="Prakash Kolhe | Cymetrix Software CEO " alt="Cymetrix Software CEO Image - Prakash Kolhe" className="h-64 w-64 mx-auto my-5 rounded-full"/>
          <div className="flex flex-col justify-center">
            <h1 className="text-center text-lg text-gray-700">
              Prakash Kolhe
            </h1>
            <div class="flex justify-center space-x-4 mt-2">
              <a className="hover:text-hover" href="https://www.linkedin.com/in/prakash-kolhe-3a42a89/" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn />
              </a>
              {/* <Link
                className="hover:text-hover"
                to='https://www.linkedin.com/in/prakash-kolhe-3a42a89/'><FaLinkedinIn /></Link> */}
            </div>
          </div>
        </div>
        <div className="w-full md:w-4/5 lg:m-auto sma:px-2 sma:mt-10 mx-auto">
          <h1 className="text-3xl text-hover">Our Founder</h1>
          <div className="w-full bg-gray-900 h-px"></div>
          <p className="leading-relaxed mt-2 text-gray-700 font-thin text-justify">Prakash is a founding member of Cymetrix Software and is also providing executive leadership to the company as CEO for growth of the company.<br /><br />

          He is 25 Yrs of veteran in IT industry.He has done his ME from REC Nagpur and MBA from IBS.<br /><br />

          He has delivered critical software systems for the clients. He has rich experience of servicing some of fortune 500 clients.<br /><br />

          He has played key leadership roles in many prestigious companies in IT industry like HCL Tech, Virtusa - Polaris, Bank of America … while working in various countries like US, Japan, UK<br /><br />

          In the recent past he was responsible for Virtusa – Polaris ‘s new business development of banking and finance industry in APAC<br /><br />

          Prakash is also a member of CSI Mumbai Chapter and has played an active role while on chapter committee. He is highly networked in the Industry.<br />
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutPage;